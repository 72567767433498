export const enum IconKeys {
	BOX_REMOVE = 'box-remove',
	GROUP = 'group',
	CONTAINER = 'meal',
	MEAL_PROBLEM = 'meal',
	DAMAGED_PACKAGE = 'package-damage',
	DAMAGED_PACKAGE2 = 'package-damage-2',
	DELIVERY_PROBLEM = 'delivery-problem',
	UNSEALED_PACKAGE = 'package-unsealed',
	TORN_PACKAGE = 'torn-package',
	OTHER = 'question',
	EMPTY_PACKAGE = 'empty-package',
	BAD_MEAL_QUALITY = 'bad-meal-quality',
	FORK_X = 'fork-x',
	WRONG_PACKAGE_CONTENT = 'fork',
	MEAL_POISONING = 'poison-meal',
	INCOMPLETE_MEAL = 'incomplete',
	CIRCLE_X = 'circle-x',
	CIRCLE_YES = 'circle-yes',
	CIRCLE_QUESTION = 'circle-question',
	DELAYED_DELIVERY = 'delivery-delayed',
	DELIVERY_QUESTION = 'delivery-question',
	MEAL_QUESTION = 'meal-question',
	SHEET_QUESTION = 'sheet-question',
	FIRE = 'fire',
	PACKAGE_DAMAGED = 'package-damaged',
	PACKAGE = 'package',
	SWOLLEN_FOIL = 'swollen-foil',
	MOLD = 'mold',
	BAD_SMELL = 'bad-smell',
	BAD_TASTE = 'bad-taste',
}

import { IconKeys } from './iconKeys'
import { NodeHeaders, NodeKeys, NodeLabels, StepType, ProblemsDescriptions, SelectOptionNames } from './keys'
import { INode } from '~/types/nodeTypes'

export const availableNodes: INode[] = [
	{
		key: NodeKeys.MODULE,
		header: NodeHeaders.MODULE,
		label: NodeLabels.MODULE,
		type: StepType.SELECT,
	},
	{
		key: NodeKeys.EMAIL_MEAL,
		header: NodeHeaders.PROBLEM_TYPE,
		label: NodeLabels.PROBLEM_TYPE,
		type: StepType.SELECT,
	},
	{
		key: NodeKeys.EMAIL_DELIVERY,
		header: NodeHeaders.PROBLEM_TYPE,
		label: NodeLabels.PROBLEM_TYPE,
		type: StepType.SELECT,
	},
	{
		key: NodeKeys.EMAIL_PACKAGE,
		header: NodeHeaders.PROBLEM_TYPE,
		label: NodeLabels.PROBLEM_TYPE,
		type: StepType.SELECT,
	},
	{
		key: NodeKeys.MEAL_PROBLEM,
		header: NodeHeaders.EMAIL,
		label: NodeLabels.EMAIL,
		type: StepType.EMAIL,
		description: ProblemsDescriptions.MEAL_PROBLEM,
		icon: IconKeys.MEAL_PROBLEM,
		selectName: SelectOptionNames.MEAL_PROBLEM,
	},
	{
		key: NodeKeys.DELIVERY_PROBLEM,
		header: NodeHeaders.EMAIL,
		label: NodeLabels.EMAIL,
		type: StepType.EMAIL,
		description: ProblemsDescriptions.DELIVERY_PROBLEM,
		icon: IconKeys.DELIVERY_PROBLEM,
		selectName: SelectOptionNames.DELIVERY_PROBLEM,
	},
	{
		key: NodeKeys.DAMAGED_PACKAGE,
		header: NodeHeaders.EMAIL,
		label: NodeLabels.EMAIL,
		type: StepType.EMAIL,
		description: ProblemsDescriptions.DAMAGED_PACKAGE,
		icon: IconKeys.DAMAGED_PACKAGE,
		selectName: SelectOptionNames.DAMAGED_PACKAGE,
	},
	{
		key: NodeKeys.CRACKED_PACKAGE,
		header: NodeHeaders.DATE,
		label: NodeLabels.DATE,
		type: StepType.DATE_DELIVERY,
		icon: IconKeys.DAMAGED_PACKAGE2,
		selectName: SelectOptionNames.CRACKED_PACKAGE,
	},
	{
		key: NodeKeys.TORN_PACKAGE,
		header: NodeHeaders.DATE,
		label: NodeLabels.DATE,
		type: StepType.DATE_DELIVERY,
		icon: IconKeys.TORN_PACKAGE,
		selectName: SelectOptionNames.TORN_PACKAGE,
	},
	{
		key: NodeKeys.UNSEALED_PACKAGE,
		header: NodeHeaders.DATE,
		label: NodeLabels.DATE,
		type: StepType.DATE_DELIVERY,
		icon: IconKeys.UNSEALED_PACKAGE,
		selectName: SelectOptionNames.UNSEALED_PACKAGE,
	},
	{
		key: NodeKeys.DAMAGED_PACKAGE_OTHER,
		header: NodeHeaders.DATE,
		label: NodeLabels.DATE,
		type: StepType.DATE_DELIVERY,
		icon: IconKeys.OTHER,
		selectName: SelectOptionNames.DAMAGED_PACKAGE_OTHER,
	},
	// {
	// 	key: NodeKeys.PROBLEM_DATE,
	// 	header: NodeHeaders.PROBLEM_DATE,
	// 	label: NodeLabels.PROBLEM_DATE,
	// 	type: StepType.DATE_PROBLEM,
	// 	requires: [NodeKeys.MEAL_POISONING],
	// },
	{
		key: NodeKeys.DELIVERY_DATE,
		header: NodeHeaders.DATE,
		label: NodeLabels.DATE,
		type: StepType.DATE_DELIVERY,
	},
	{
		key: NodeKeys.PICK_DIET,
		header: NodeHeaders.PICK_DIET,
		label: NodeLabels.PICK_DIET,
		type: StepType.DIET_PICKER,
	},
	{
		key: NodeKeys.PICK_DIET_MULTIPLE,
		header: NodeHeaders.PICK_DIET_MULTIPLE,
		label: NodeLabels.PICK_DIET,
		type: StepType.DIET_PICKER_MULTIPLE,
	},
	{
		key: NodeKeys.PICK_MEALS,
		header: NodeHeaders.PICK_MEALS,
		label: NodeLabels.PICK_MEALS,
		type: StepType.MEAL_PICKER,
		requires: [NodeKeys.MEAL_PROBLEM, NodeKeys.DAMAGED_PACKAGE],
	},
	{
		key: NodeKeys.REQUIRED_PHOTO,
		header: NodeHeaders.PHOTO,
		label: NodeLabels.PHOTO,
		type: StepType.REQUIRED_PHOTO,
		requires: [
			NodeKeys.CRACKED_PACKAGE,
			NodeKeys.UNSEALED_PACKAGE,
			NodeKeys.FOREIGN_BODY,
			NodeKeys.SWOLLEN_FOIL,
			NodeKeys.MOLD_IN_MEAL,
			NodeKeys.DIFFERENT_MEAL_THAN_ORDERED,
			NodeKeys.MEAL_INCOMPATIBLE_WITH_DIET,
			NodeKeys.DIFFERENT_CALORIES_THAN_ORDERED,
			NodeKeys.DIFFERENT_CALORIES_THAN_ORDERED,
			NodeKeys.MEAL_LACKS_INGREDIENTS,
			NodeKeys.TORN_PACKAGE,
		],
	},
	{
		key: NodeKeys.OPTIONAL_PHOTO,
		header: NodeHeaders.PHOTO,
		label: NodeLabels.PHOTO,
		type: StepType.OPTIONAL_PHOTO,
		requires: [
			NodeKeys.DAMAGED_PACKAGE_OTHER,
			NodeKeys.SMELL_OF_MEAL,
			NodeKeys.BAD_TASTE_OF_MEAL,
			NodeKeys.BAD_MEAL_QUALITY_OTHER,
			NodeKeys.WRONG_PACKAGE_CONTENT_OTHER,
			NodeKeys.DELIVERY_COMMENT,
			NodeKeys.DELIVERY_PROBLEM_OTHER,
			NodeKeys.MEAL_PROBLEM_OTHER,
		],
	},
	{
		key: NodeKeys.FOREIGN_BODY,
		header: NodeHeaders.DATE,
		label: NodeLabels.DATE,
		type: StepType.DATE_DELIVERY,
		requires: [NodeKeys.DAMAGED_PACKAGE_OTHER],
		icon: IconKeys.FORK_X,
		selectName: SelectOptionNames.FOREIGN_BODY,
	},
	// {
	// 	key: NodeKeys.MEAL_POISONING,
	// 	header: NodeHeaders.DATE,
	// 	label: NodeLabels.DATE,
	// 	type: StepType.DATE_DELIVERY,
	// 	requires: [NodeKeys.DAMAGED_PACKAGE_OTHER],
	// 	icon: IconKeys.MEAL_POISONING,
	// 	selectName: SelectOptionNames.MEAL_POISONING,
	// },
	{
		key: NodeKeys.DESCRIPTION,
		header: NodeHeaders.DESCRIPTION,
		label: NodeLabels.DESCRIPTION,
		type: StepType.NOTES,
		requires: [NodeKeys.MEAL_PROBLEM, NodeKeys.DAMAGED_PACKAGE, NodeKeys.DELIVERY_PROBLEM],
	},
	{
		key: NodeKeys.COMPLAINT_METHOD,
		header: NodeHeaders.COMPLAINT_METHOD,
		label: NodeLabels.COMPLAINT_METHOD,
		type: StepType.COMPLAINT_METHODS,
	},
	{
		key: NodeKeys.MEAL_PROBLEM_OTHER,
		header: NodeHeaders.DATE,
		label: NodeLabels.DATE,
		type: StepType.DATE_DELIVERY,
		icon: IconKeys.OTHER,
		selectName: SelectOptionNames.MEAL_PROBLEM_OTHER,
	},
	{
		key: NodeKeys.BAD_MEAL_QUALITY,
		header: NodeHeaders.BAD_MEAL_QUALITY,
		label: NodeLabels.BAD_MEAL_QUALITY,
		type: StepType.SELECT_BAD_MEAL_QUALITY,
		icon: IconKeys.BAD_MEAL_QUALITY,
		selectName: SelectOptionNames.BAD_MEAL_QUALITY,
	},
	{
		key: NodeKeys.BAD_MEAL_QUALITY_OTHER,
		header: NodeHeaders.DATE,
		label: NodeLabels.DATE,
		type: StepType.DATE_DELIVERY,
		selectName: SelectOptionNames.BAD_MEAL_QUALITY_OTHER,
		icon: IconKeys.OTHER,
	},
	{
		key: NodeKeys.SWOLLEN_FOIL,
		header: NodeHeaders.DATE,
		label: NodeLabels.DATE,
		type: StepType.DATE_DELIVERY,
		selectName: SelectOptionNames.SWOLLEN_FOIL,
		icon: IconKeys.SWOLLEN_FOIL,
	},
	{
		key: NodeKeys.MOLD_IN_MEAL,
		header: NodeHeaders.DATE,
		label: NodeLabels.DATE,
		type: StepType.DATE_DELIVERY,
		selectName: SelectOptionNames.MOLD_IN_MEAL,
		icon: IconKeys.MOLD,
	},
	{
		key: NodeKeys.SMELL_OF_MEAL,
		header: NodeHeaders.DATE,
		label: NodeLabels.DATE,
		type: StepType.DATE_DELIVERY,
		selectName: SelectOptionNames.SMELL_OF_MEAL,
		icon: IconKeys.BAD_SMELL,
	},
	{
		key: NodeKeys.BAD_TASTE_OF_MEAL,
		header: NodeHeaders.DATE,
		label: NodeLabels.DATE,
		type: StepType.DATE_DELIVERY,
		selectName: SelectOptionNames.BAD_TASTE_OF_MEAL,
		icon: IconKeys.BAD_TASTE,
	},
	{
		key: NodeKeys.SWOLLEN_FOIL,
		header: NodeHeaders.DATE,
		label: NodeLabels.DATE,
		type: StepType.DATE_DELIVERY,
	},
	{
		key: NodeKeys.EMPTY_PACKAGE,
		header: NodeHeaders.EMPTY_PACKAGE,
		label: NodeLabels.EMPTY_PACKAGE,
		type: StepType.WAS_PACKAGE_EMPTY,
		icon: IconKeys.EMPTY_PACKAGE,
		selectName: SelectOptionNames.EMPTY_PACKAGE,
	},
	{
		key: NodeKeys.IS_STICKER_ON_PACKAGE,
		header: NodeHeaders.STICKER,
		label: NodeLabels.STICKER,
		type: StepType.STICKER_ON_PACKAGE,
	},
	{
		key: NodeKeys.WRONG_PACKAGE_CONTENT,
		header: NodeHeaders.EVENT_TYPE,
		label: NodeLabels.WRONG_PACKAGE_CONTENT,
		type: StepType.SELECT_WRONG_PACKAGE_CONTENT,
		icon: IconKeys.WRONG_PACKAGE_CONTENT,
		selectName: SelectOptionNames.WRONG_PACKAGE_CONTENT,
	},
	{
		key: NodeKeys.DIFFERENT_MEAL_THAN_ORDERED,
		header: NodeHeaders.DATE,
		label: NodeLabels.DATE,
		type: StepType.DATE_DELIVERY,
		selectName: SelectOptionNames.DIFFERENT_MEAL_THAN_ORDERED,
		icon: IconKeys.MEAL_QUESTION,
	},
	{
		key: NodeKeys.MEAL_INCOMPATIBLE_WITH_DIET,
		header: NodeHeaders.DATE,
		label: NodeLabels.DATE,
		type: StepType.DATE_DELIVERY,
		selectName: SelectOptionNames.MEAL_INCOMPATIBLE_WITH_DIET,
		icon: IconKeys.SHEET_QUESTION,
	},
	{
		key: NodeKeys.DIFFERENT_CALORIES_THAN_ORDERED,
		header: NodeHeaders.DATE,
		label: NodeLabels.DATE,
		type: StepType.DATE_DELIVERY,
		selectName: SelectOptionNames.DIFFERENT_CALORIES_THAN_ORDERED,
		icon: IconKeys.FIRE,
	},
	{
		key: NodeKeys.MEAL_LACKS_INGREDIENTS,
		header: NodeHeaders.DATE,
		label: NodeLabels.DATE,
		type: StepType.DATE_DELIVERY,
		icon: IconKeys.INCOMPLETE_MEAL,
		selectName: SelectOptionNames.MEAL_LACKS_INGREDIENTS,
	},
	{
		key: NodeKeys.WRONG_PACKAGE_CONTENT_OTHER,
		header: NodeHeaders.DATE,
		label: NodeLabels.DATE,
		type: StepType.DATE_DELIVERY,
		selectName: SelectOptionNames.WRONG_PACKAGE_CONTENT_OTHER,
		icon: IconKeys.OTHER,
	},
	{
		key: NodeKeys.PACKAGE_MISSING,
		header: NodeHeaders.DATE,
		label: NodeLabels.DATE,
		type: StepType.DATE_DELIVERY,
		selectName: SelectOptionNames.PACKAGE_MISSING,
		icon: IconKeys.CIRCLE_X,
	},
	{
		key: NodeKeys.DELAYED_DELIVERY,
		header: NodeHeaders.DATE,
		label: NodeLabels.DATE,
		type: StepType.DATE_DELIVERY,
		selectName: SelectOptionNames.DELAYED_DELIVERY,
		icon: IconKeys.DELAYED_DELIVERY,
	},
	{
		key: NodeKeys.DELIVERY_COMMENT,
		header: NodeHeaders.DATE,
		label: NodeLabels.DATE,
		type: StepType.DATE_DELIVERY,
		selectName: SelectOptionNames.DELIVERY_COMMENT,
		icon: IconKeys.DELIVERY_QUESTION,
	},
	{
		key: NodeKeys.DELIVERY_PROBLEM_OTHER,
		header: NodeHeaders.DATE,
		label: NodeLabels.DATE,
		type: StepType.DATE_DELIVERY,
		selectName: SelectOptionNames.DELIVERY_PROBLEM_OTHER,
		icon: IconKeys.OTHER,
	},

	{
		key: NodeKeys.SUMMARY,
		header: NodeHeaders.SUMMARY,
		label: NodeLabels.SUMMARY,
		type: StepType.SUMMARY,
	},

	{
		key: NodeKeys.SUCCESS,
		header: NodeHeaders.SUCCESS,
		label: '',
		type: StepType.SUCCESS,
	},
]

import { availableNodes } from '~/data/graph'
import { INode, INodeHistory, NodeHistoryValue } from '~/types/nodeTypes'
import { NodeKeys } from '~/data/keys'

export const useNavigationService = () => {
	const changeSiteState = (nodeKey: NodeKeys, value: NodeHistoryValue) => {
		const siteStore = useSiteStore()
		const nextNode = availableNodes.find((node) => node.key === nodeKey)
		siteStore.addHistory(nextNode as INode, value)
		if (nextNode) {
			siteStore.setCurrentNode(nextNode)
		}
	}

	const changeSiteStateBackwards = (nodeKey: NodeKeys, step: number) => {
		const siteStore = useSiteStore()
		const nextNode = availableNodes.find((node) => node.key === nodeKey)
		siteStore.removeStepsFromHistory(step)
		if (nextNode) {
			siteStore.setCurrentNode(nextNode)
		}
	}
	const getPreviousStep = () => {
		const siteStore = useSiteStore()
		const history = siteStore.getHistory
		if (!history || history.length <= 1) return
		const previousNode = history[history.length - 2]
		siteStore.popHistory()
		siteStore.setCurrentNode(previousNode)
	}

	const getLastValueFromHistory = () => {
		const siteStore = useSiteStore()
		const history = siteStore.getHistory
		if (!history) return null
		const currentState = siteStore.getCurrentNode
		const historyKey = history[history.length - 1].key
		if (currentState?.key === historyKey) {
			return history[history.length - 1].value
		}
		return null
	}

	const getPhotosFromHistory = () => {
		const siteStore = useSiteStore()
		const history = siteStore.getHistory
		if (!history) return null
		const index = history.findIndex((node) => node.key === NodeKeys.OPTIONAL_PHOTO || node.key === NodeKeys.REQUIRED_PHOTO)
		return index > -1 ? history[index].value : null
	}

	const getEmailFromHistory = () => {
		const siteStore = useSiteStore()
		const history = siteStore.getHistory
		if (!history) return null
		const index = history.findIndex((node) => node.type === StepType.EMAIL)
		return index > -1 ? history[index].value : null
	}

	const getDietFromHistory = () => {
		const siteStore = useSiteStore()
		const history = siteStore.getHistory
		if (!history) return null
		const index = history.findIndex((node) => node.key === NodeKeys.PICK_DIET || node.key === NodeKeys.PICK_DIET_MULTIPLE)
		const diets = history[index].value
		if (Array.isArray(diets)) {
			return diets.join(', ')
		} else return diets
	}

	const getMealsFromHistory = () => {
		const siteStore = useSiteStore()
		const history = siteStore.getHistory
		if (!history) return null
		const index = history.findIndex((node) => node.key === NodeKeys.PICK_MEALS)
		return index > -1 ? history[index].value : null
	}

	const getDescriptionFromHistory = () => {
		const siteStore = useSiteStore()
		const history = siteStore.getHistory
		if (!history) return null
		const index = history.findIndex((node) => node.key === NodeKeys.DESCRIPTION)
		return index > -1 ? history[index].value : null
	}

	const getDeliveryDateFromHistory = () => {
		const siteStore = useSiteStore()
		const history = siteStore.getHistory
		if (!history) return null
		const index = history.findIndex((node) => node.type === StepType.DATE_DELIVERY)
		return index > -1 ? history[index].value : null
	}

	const getReasonFromHistory = () => {
		const siteStore = useSiteStore()
		const history = siteStore.getHistory
		if (!history) return null
		const selects = history.filter(
			(node) =>
				node.type === StepType.SELECT || node.type === StepType.SELECT_BAD_MEAL_QUALITY || node.type === StepType.SELECT_WRONG_PACKAGE_CONTENT
		)
		return selects
	}

	const checkRequirementsForNodes = (availableNodeKeys: NodeKeys[]) => {
		let requiresMeet = false

		return availableNodeKeys.filter((nodeKey) => {
			const possibleNodeRequirements = getPossibleNodes(nodeKey)
			// to do: check if all possible nodes with requirements are checked before returning nodeKey
			if (possibleNodeRequirements) {
				if (!requiresMeet) {
					const { result, nextNodeKey } = checkIfRequirementIsFullfilled(possibleNodeRequirements)
					requiresMeet = result
					if (nextNodeKey) return nextNodeKey
				}
			} else if (!requiresMeet) {
				return true
			}
			return false
		})
	}

	const getPossibleNodes = (nodeKey: NodeKeys) => {
		return availableNodes.find((node) => node.key === nodeKey)?.requires
	}
	const checkIfRequirementIsFullfilled = (possibleNodeRequirements: NodeKeys[]) => {
		const siteStore = useSiteStore()
		const history = siteStore.getHistory ?? []
		for (let i = 0; i < possibleNodeRequirements.length; i++) {
			const nodeKey = checkRequirementsInHistory(i, possibleNodeRequirements, history)
			if (nodeKey) return { result: true, nextNodeKey: nodeKey }
		}
		return { result: false, nextNodeKey: '' }
	}

	const checkRequirementsInHistory = (i: number, possibleNodeRequirements: NodeKeys[], history: INodeHistory[]) => {
		return history.find((node) => node.key === possibleNodeRequirements?.[i])?.key
	}

	const getNodes = (searchedNodes: NodeKeys[]) => {
		const allSearchedNodes: INode[] = []
		searchedNodes.forEach((searchNode) => {
			const node = availableNodes.find((node) => node.key === searchNode)
			if (node) allSearchedNodes.push(node)
		})
		return allSearchedNodes
	}

	const convertKeyToName = (key: NodeHistoryValue) => {
		const node = availableNodes.find((node) => node.key === key)
		return node && node.selectName ? node.selectName : key
	}

	return {
		changeSiteState,
		getPreviousStep,
		getLastValueFromHistory,
		changeSiteStateBackwards,
		getPhotosFromHistory,
		getEmailFromHistory,
		checkRequirementsForNodes,
		getDietFromHistory,
		getMealsFromHistory,
		getDescriptionFromHistory,
		getDeliveryDateFromHistory,
		getReasonFromHistory,
		getNodes,
		convertKeyToName,
	}
}

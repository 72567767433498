import { INodeHeader } from '~/types/nodeTypes'

export const enum SelectOptionNames {
	// available paths group
	MEAL_PROBLEM = 'Problem z posiłkami',
	DELIVERY_PROBLEM = 'Problem z dostawą',
	DAMAGED_PACKAGE = 'Problem z opakowaniem',

	// meal problem - single paths group
	WRONG_PACKAGE_CONTENT = 'Pomylony posiłek',
	EMPTY_PACKAGE = 'Brak posiłku w torbie',
	BAD_MEAL_QUALITY = 'Zła jakość posiłku',
	FOREIGN_BODY = 'Ciało obce w posiłku',
	MEAL_POISONING = 'Zatrułem się posiłkiem',
	MEAL_PROBLEM_OTHER = 'Inny',
	MEAL_LACKS_INGREDIENTS = 'Niekompletny posiłek',

	// meal problem - wrong package content group
	DIFFERENT_MEAL_THAN_ORDERED = 'Otrzymałem/am inny posiłek niż zamówiłem/am',
	MEAL_INCOMPATIBLE_WITH_DIET = 'Posiłek niezgodny z opisem',
	DIFFERENT_CALORIES_THAN_ORDERED = 'Posiłek pochodzi z innej kaloryczności',
	WRONG_PACKAGE_CONTENT_OTHER = 'Inne',

	// meal problem - bad meal quality group
	SWOLLEN_FOIL = 'Spuchnięta folia',
	MOLD_IN_MEAL = 'Nalot lub pleśń w posiłku',
	SMELL_OF_MEAL = 'Nieświeży zapach posiłku',
	BAD_TASTE_OF_MEAL = 'Nieświeży smak',
	BAD_MEAL_QUALITY_OTHER = 'Inne',

	// damaged package group
	CRACKED_PACKAGE = 'Pęknięte opakowanie',
	UNSEALED_PACKAGE = 'Rozszczelniona folia',
	TORN_PACKAGE = 'Rozerwana torba',
	DAMAGED_PACKAGE_OTHER = 'Inne',

	// delivery problem group
	PACKAGE_MISSING = 'Brak paczki',
	DELAYED_DELIVERY = 'Opóźniona dostawa',
	DELIVERY_COMMENT = 'Uwagi do dostawy',
	DELIVERY_PROBLEM_OTHER = 'Inny problem z dostawą',
}

export const enum StepType {
	EMAIL = 'email',
	SELECT = 'select',
	SELECT_BAD_MEAL_QUALITY = 'selectBadMealQuality',
	SELECT_WRONG_PACKAGE_CONTENT = 'selectWrongPackageContent',
	DATE_DELIVERY = 'dateDelivery',
	DATE_PROBLEM = 'dateProblem',
	MEAL_PICKER = 'mealPicker',
	DIET_PICKER = 'dietPicker',
	DIET_PICKER_MULTIPLE = 'dietPickerMulti',
	REQUIRED_PHOTO = 'photoRequired',
	OPTIONAL_PHOTO = 'photoOptional',
	NOTES = 'notes',
	COMPLAINT_METHODS = 'complaintMethods',
	WAS_PACKAGE_EMPTY = 'wasPackageEmpty',
	STICKER_ON_PACKAGE = 'stickerOnPackage',
	SUMMARY = 'Podsumowanie',
	SUCCESS = 'Dziękujemy za kontakt',
}

export const NodeHeaders: { [key: string]: INodeHeader } = {
	MODULE: { text: 'Co się wydarzyło?' },
	EMAIL: { text: 'Podaj adres e-mail' },

	PROBLEM_TYPE: { text: 'Określ typ problemu', highlightedWordsAmount: 2 },
	EVENT_TYPE: { text: 'Zaznacz co się wydarzyło' },
	DATE: { text: 'Zaznacz datę wystąpienia problemu' },
	STICKER: { text: 'Czy etykieta była naklejona?' },

	// available paths group
	DELIVERY_PROBLEM: { text: 'Problem z dostawą' },
	DAMAGED_PACKAGE: { text: 'Uszkodzone opakowanie' },
	// common nodes group
	DELIVERY_DATE: { text: 'Zaznacz datę wystąpienia problemu' },
	PROBLEM_DATE: { text: 'Zaznacz datę wystąpienia problemu' },
	PICK_DIET: { text: 'Zaznacz dietę, której dotyczy problem' },
	PICK_DIET_MULTIPLE: { text: 'Zaznacz diety, których dotyczy problem' },
	PICK_MEALS: { text: 'Zaznacz posiłki, których dotyczy problem' },
	PHOTO: { text: 'Dodaj zdjęcia pokazujące problem' },
	DESCRIPTION: { text: 'Dodaj opis problemu' },
	COMPLAINT_METHOD: { text: 'Reklamacja' },

	// meal problem - wrong package content group
	WRONG_PACKAGE_CONTENT: { text: 'Zaznacz co się wydarzyło' },

	// meal problem - bad meal quality group
	BAD_MEAL_QUALITY: { text: 'Zła jakość posiłku' },

	// meal problem - single paths group
	IS_STICKER_ON_PACKAGE: { text: 'Czy etykieta była naklejona' },
	EMPTY_PACKAGE: { text: 'Czy paczka była otwarta lub uszkodzona?', highlightedWordsAmount: 3 },

	// summary
	SUMMARY: { text: 'Podsumowanie zgłoszenia' },

	SUCCESS: { text: 'Dziękujemy za kontakt', highlightedWordsAmount: 2 },
}

export const enum NodeLabels {
	EMAIL = 'Email',
	MODULE = 'Problem',

	PROBLEM_TYPE = 'Typ problemu',
	EVENT_TYPE = 'Co się wydarzyło',

	DATE = 'Kalendarz',

	EMPTY_PACKAGE = 'Czy paczka była otwarta?',
	STICKER = 'Czy etykieta była naklejona?',

	// available paths group
	MEAL_PROBLEM = 'Problem z posiłkiem',
	DELIVERY_PROBLEM = 'Problem z dostawą',
	DAMAGED_PACKAGE = 'Uszkodzone opakowanie',

	// common nodes group
	PROBLEM_DATE = 'Data problemu',
	PICK_DIET = 'Dieta',
	PICK_MEALS = 'Posiłki',
	PHOTO = 'Zdjęcia',
	DESCRIPTION = 'Opis',
	COMPLAINT_METHOD = 'Reklamacja',

	// meal problem - wrong package content group
	WRONG_PACKAGE_CONTENT = 'Posiłek niezgodny z zamówieniem',

	// meal problem - bad meal quality group
	BAD_MEAL_QUALITY = 'Zła jakość posiłku',

	// summary
	SUMMARY = 'Podsumowanie',
}

export const enum ProblemsDescriptions {
	MEAL_PROBLEM = 'Posiłki w Twojej paczce różnią się od wybranych lub ich jakość jest niezadowalająca',
	DAMAGED_PACKAGE = 'Opakowanie posiłku lub torba uległy uszkodzeniu',
	DELIVERY_PROBLEM = 'Twoje zamówienie nie zostało dostarczone lub miało opóźnienie w dostawie',
}

export enum NodeKeys {
	MODULE = 1,

	// available paths group

	MEAL_PROBLEM,
	DELIVERY_PROBLEM,
	DAMAGED_PACKAGE,
	EMAIL_MEAL,
	EMAIL_DELIVERY,
	EMAIL_PACKAGE,

	// common nodes group (at 8)
	DELIVERY_DATE,
	// PROBLEM_DATE,
	PICK_MEALS,
	PICK_DIET,
	PICK_DIET_MULTIPLE,
	REQUIRED_PHOTO,
	OPTIONAL_PHOTO,
	DESCRIPTION,
	COMPLAINT_METHOD,

	// meal problem - wrong package content group
	WRONG_PACKAGE_CONTENT,
	DIFFERENT_MEAL_THAN_ORDERED,
	MEAL_INCOMPATIBLE_WITH_DIET,
	DIFFERENT_CALORIES_THAN_ORDERED,
	WRONG_PACKAGE_CONTENT_OTHER,

	// meal problem - bad meal quality group (at 22)
	SWOLLEN_FOIL,
	MOLD_IN_MEAL,
	SMELL_OF_MEAL,
	BAD_TASTE_OF_MEAL,
	BAD_MEAL_QUALITY_OTHER,

	// meal problem - single paths group (at 27)
	BAD_MEAL_QUALITY,
	FOREIGN_BODY,
	// MEAL_POISONING,
	MEAL_PROBLEM_OTHER,
	MEAL_LACKS_INGREDIENTS,
	IS_STICKER_ON_PACKAGE,
	EMPTY_PACKAGE,

	// damaged package group
	CRACKED_PACKAGE,
	UNSEALED_PACKAGE,
	TORN_PACKAGE,
	DAMAGED_PACKAGE_OTHER,

	// delivery problem group
	PACKAGE_MISSING,
	DELAYED_DELIVERY,
	DELIVERY_COMMENT,
	DELIVERY_PROBLEM_OTHER,

	// summary
	SUMMARY,

	// SUCCESS
	SUCCESS,
}

import { defineStore } from 'pinia'
import { computed, reactive, type ComputedRef } from 'vue'
import { IFood } from '~/components/Global/FoodComponent/FoodComponent.vue'
import { INode, INodeHistory, NodeHistoryValue } from '~/types/nodeTypes'

export interface ISiteStateStore {
	currentNode: INode | null
	history: INodeHistory[]
	closeModal: boolean
	diets: IFood[]
}

export interface ISiteStore {
	state: ISiteStateStore
	setCurrentNode: (currentNode: INode) => void
	setHistory: (history: INodeHistory[]) => void
	setCloseModal: (closeModal: boolean) => void
	setDiets: (diets: IFood[]) => void
	getCurrentNode: ComputedRef<INode | null>
	getHistory: ComputedRef<INodeHistory[] | null>
	getCloseModal: ComputedRef<boolean>
	getDiets: ComputedRef<IFood[]>
	addHistory: (node: INode, value: NodeHistoryValue) => void
	popHistory: () => void
	clearHistory: () => void
	removeStepsFromHistory: (stepsAmount: number) => void
}

export const useSiteStore = defineStore('siteStore', (): ISiteStore => {
	const _state = reactive<ISiteStateStore>({
		currentNode: null,
		history: [],
		diets: [],
		closeModal: false,
	})

	const setCurrentNode = (currentNode: INode) => {
		_state.currentNode = currentNode
	}

	const setHistory = (history: INodeHistory[]) => {
		_state.history = history
	}

	const setDiets = (diets: IFood[]) => {
		_state.diets = diets
	}

	const setCloseModal = (closeModal: boolean) => {
		_state.closeModal = closeModal
	}

	const clearHistory = () => {
		_state.history = []
	}

	const addHistory = (node: INode, value: NodeHistoryValue) => {
		const step = _state.history.length + 1
		_state.history.push({ ...node, step, value: '' })
		const objIndex = _state.history.findIndex((item) => item.step === step - 1)
		if (objIndex >= 0) _state.history[objIndex].value = value
	}

	const popHistory = () => {
		if (_state.history.length > 1) _state.history.pop()
	}

	const removeStepsFromHistory = (step: number) => {
		_state.history = _state.history.slice(0, step)
	}

	const getCurrentNode = computed(() => {
		return _state.currentNode
	})

	const getCloseModal = computed(() => {
		return _state.closeModal
	})

	const getHistory = computed(() => {
		return _state.history
	})

	const getDiets = computed(() => {
		return _state.diets
	})

	return {
		state: _state,
		setCurrentNode,
		setHistory,
		setCloseModal,
		setDiets,
		getCurrentNode,
		getHistory,
		getCloseModal,
		getDiets,
		addHistory,
		popHistory,
		clearHistory,
		removeStepsFromHistory,
	}
})
